/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CloseBtn } from "../svg/AllSvg";
import useAxios from "../../shared/hooks/use-axios";
import { url } from "../../shared/utils/url";
import OtpInput from "react-otp-input";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { encodeJwt } from "../../shared/utils/jwt";

const VerifyOtp = () => {
    const verifyOtpOpen: boolean = useSelector((state: any) => state.data.verifyOtpOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [submit, setSubmit] = useState<boolean>(false);
    const [sendSubmit, setSendSubmit] = useState<boolean>(false);
    const [otp, setOtp] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [seconds, setSeconds] = useState<number>(60);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const popupRef = useRef<HTMLDivElement>(null);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setVerifyOtpOpen(false))
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                dispatch(dataActions.setVerifyOtpOpen(false));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    // for seconds count 
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds((prevTime) => prevTime - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleSend = async () => {
        try {
            setSendSubmit(true)
            let obj: any = {
                email: localStorage.getItem("field_value")
            }

            const res: any = await useAxios.post(`/${lang + url?.login}`, obj);
            if (res && res?.status === 200) {
                setSeconds(60);
                setSendSubmit(false)
                setOtp(null)
            }
        } catch (error: any) {
            setSendSubmit(false)
            if (!!error?.response?.data?.non_field_errors) {
                setError({ ...error, password: error?.response?.data?.non_field_errors?.toString() })
            }
            console.error("error while send otp", error);
        }
    }


    const verifyOtpObj: any = {
        email: localStorage.getItem("field_value")
    };

    const verifyOtpSchema = yup.object().shape({
        email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email"))
    });

    const handleSubmit = async (values: any) => {
        try {
            if (!!!otp) {
                setError(t("auth.otp_err"));
                return;
            }
            setSubmit(true)
            let encodedOtp: any = await encodeJwt(otp)
            let obj = {
                username: values.email,
                otp: encodedOtp
            }
            const res: any = await useAxios.post(`/${lang + url?.login}`, obj);

            if (res && res?.status === 200) {
                dispatch(dataActions.setVerifyOtpOpen(false))
                dispatch(dataActions.setResetPasswordOpen(true))
                setSubmit(false)
            }
        } catch (error: any) {
            setSubmit(false)
            if (!!error?.response?.data?.non_field_errors) {
                setError({ ...error, password: error?.response?.data?.non_field_errors?.toString() })
            }
            console.error("error while email verification", error);
        }
    }

    const getFormattedTime = () => {
        const minutes = Math.floor(seconds / 60);
        const second = seconds % 60;
        return `${minutes === 0 ? `${second < 10 ? `0${second}S` : second}S` : `${minutes}:${second < 10 ? `0${second}` : second}`}`
    };

    const percentage = (seconds / 60) * 100;

    return (
        <>
            {verifyOtpOpen &&
                <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] sm:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-full sm:w-[600px] md:w-[745px] lg:w-[906px] h-full lg:min-h-[600] shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]" ref={popupRef}>
                            <div className="w-full h-[250px] lg:h-auto lg:w-[427px] lg:max-h-[600px] relative">
                                <img src={require("../../assets/images/login-bg.png")} alt="login-bg" className="w-full h-[250px] lg:h-full object-cover rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] " />
                                <img src={require("../../assets/images/pattern3.png")} alt="pattern" className="absolute left-0 bottom-0 lg:rounded-bl-[20px]" />
                            </div>

                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="z-[1] cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setVerifyOtpOpen(false))}>
                                <CloseBtn />
                            </button>

                            <Formik
                                initialValues={verifyOtpObj}
                                validationSchema={verifyOtpSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ errors }) => (
                                    <Form className="flex flex-col relative w-full lg:w-[calc(100%-427px)] lg:max-h-full items-start justify-center p-[20px] sm:p-[30px] lg:p-[47px]">
                                        <h1 className="text-primary dark:!text-primary text-xl sm:text-2xl 1xl:text-3xl font-bold font-caudex">{t("auth.verifyOtpTitle")}</h1>
                                        <p className="text-[#606060] text-xs sm:text-sm font-normal pt-[4px]">{t("auth.verifyOtpDesc")}</p>

                                        <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[30px] w-full">
                                            <div className="flex flex-col gap-[10px] group-input">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={(otp: any) => { setOtp(otp); setError(""); }}
                                                    numInputs={6}
                                                    shouldAutoFocus={true}
                                                    renderInput={(props: any) => <input {...props} />}
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                                {(!!error?.email && !!!errors?.email) && <div className="text-red-500 text-xs">{t("auth.form.validation.incorrect_otp")}</div>}
                                            </div>
                                        </div>

                                        <button type="submit" className="rounded-[10px]  h-[40px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary disabled:opacity-[0.3] disabled:cursor-not-allowed w-full mt-[25px]  hover:shadow-[0_0_6px_0px_#61095A] focus:shadow-[0_0_6px_0px_#61095A] " disabled={!submit ? false : true} >{t("auth.verifyContinue")}</button>

                                        {seconds > 0 && <div className="flex items-center justify-center w-full pt-[20px]">
                                            <div className="w-[42px] h-[42px]">
                                                <CircularProgressbarWithChildren value={percentage}
                                                    styles={buildStyles({
                                                        pathColor: "#61095A",
                                                        trailColor: "#D0D0D0",
                                                    })} strokeWidth={8}>
                                                    <p className="text-primary font-semibold text-sm">
                                                        {getFormattedTime()}
                                                    </p>
                                                </CircularProgressbarWithChildren >
                                            </div>
                                        </div>}

                                        <div className="flex items-center justify-center w-full pt-[8px]">
                                            <div className="text-[#606060] text-xs font-normal ">{t("auth.receive")} &nbsp;<button className="!text-primary text-xs font-normal cursor-pointer focus:underline hover:underline disabled:opacity-[0.5] disabled:cursor-not-allowed disabled:no-underline" type="button" onClick={handleSend} disabled={sendSubmit}>{t("auth.resend")}</button></div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default VerifyOtp;